@font-face {
  font-family: "CookieRun";
  src: local("CookieRun"), url("cr_bold.otf") format("truetype");
  font-weight: normal;
}
body {
  font-family: "CookieRun";
}
p.text-outline-red {
  color: red;
  -webkit-font-smoothing: antialiased;
  text-shadow: #000 0px 0px 4px, #000 0px 0px 4px, #000 0px 0px 4px,
    #000 0px 0px 4px, #000 0px 0px 4px, #000 0px 0px 4px !important;
  -webkit-font-smoothing: antialiased;
}
p.text-outline {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  text-shadow: #000 0px 0px 4px, #000 0px 0px 4px, #000 0px 0px 4px,
    #000 0px 0px 4px, #000 0px 0px 4px, #000 0px 0px 4px !important;
  -webkit-font-smoothing: antialiased;
}
p.text-outline.text-red {
  color: #ed6762;
}
.ui.sortable.table thead th.sorted,
.ui.sortable.table thead th.sorted:hover,
.ui.sortable.table thead th:hover {
  background: #f2f2f2;
}
.bg {
  background-image: url("./images/bg.png");
  background-size: 100% 100%;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  transition: all 0.3s;
  background-image: url("./images/button.png");
  background-size: 100% 100%;
  background-color: transparent;
}
.page-component {
  background-image: unset !important;
}
button.large {
  padding: 10px 20px;
  font-size: 20px;
}
button:hover {
  filter: brightness(1.2);
}
button:focus {
  filter: brightness(0.8);
}
.react-datepicker {
  display: flex;
}
.react-datepicker__input-container {
  border: 1px solid #e4e4e7;
  border-radius: 2px;
  padding: 8px;
}
.react-datepicker__input-container input {
  outline: none;
  width: 100%;
}
.root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.font-clock {
  font-family: "Orbitron", sans-serif;
}
.Toastify__toast {
  background-color: #505780;
  color: white;
}
.Toastify__close-button > svg {
  color: white;
}
.green-button {
  background: #2e9349;
  border: 2px solid black;
}
.green-button:hover {
  filter: brightness(1.2);
}
.bg-popup {
  background: rgba(0, 0, 0, 0.8);
}

.rc-slider-handle {
  cursor: pointer !important;
  transform: translateX(0%) translateY(2px) !important;
  width: 26px !important;
  height: 26px !important;
  z-index: 9;
}

.rc-slider,
.rc-slider-horizontal {
  transform: translateY(7px);
}

.rc-slider-rail {
  height: 20px !important;
  border-radius: 0 !important;
  z-index: 9;
  margin-top: 0.4px;
  transform: translateX(18px);
}
.captcha-slider .rc-slider-rail {
  height: 20px !important;
  border-radius: 0 !important;
  z-index: 9;
  margin-top: 0.4px;
  transform: translateX(0px) !important;
  display: flex;
  text-align: center;
  justify-self: center;
}
.captcha-slider .rc-slider-rail::after {
  content: "Drag the slider to fit the puzzle piece";
  font-size: 13px !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
}
.rc-slider-track {
  height: 20px !important;
  border-radius: 0 !important;
  z-index: 9;
}

.rc-slider-handle {
  border-radius: 12% !important;
  opacity: 1 !important;
}

.rc-slider-handle::after {
  content: "| |";
  font-size: 13px !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.rc-slider-handle:hover,
.rc-slider-handle:focus {
  border-width: 1px;
  border-color: #ebc276 !important;
  box-shadow: unset !important;
}

.rc-slider-disabled {
  padding: unset !important;
}

.button-logo {
  width: 90px;
  height: 68px;
  cursor: pointer;
  border: 1px solid black;
  justify-content: center;
  align-self: center;
  display: flex;
  border-radius: 12px;
}

.button-logo img {
  width: 52px;
  height: 52px;
  margin: auto;
}

.button-logo {
  transition: all 0.3s;
  background-image: url("./images/button-bg.png");
  background-size: 100% 100%;
  background-position: 0% 4%;
  background-color: transparent !important;
}

.green-art-button {
  background-image: url("./images/green-button.png");
  background-size: 100% 100%;
  background-position: 0% 4%;
}

.red-art-button {
  background-image: url("./images/red-button.png");
  background-size: 100% 100%;
  background-position: 0% 4%;
}

.button-logo:hover {
  filter: brightness(1.2);
}

.button-logo-container {
  width: 90px;
}

.button-logo-container div {
  color: #8690b1;
  text-align: center;
  word-wrap: break-word;
  font-size: 12px;
  font-weight: bold;
}

.text-red {
  color: #a8464f;
}

.br {
  background-color: #383e65;
  height: 4px;
  width: 60%;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  margin: auto;
}

.loader {
  border: 6px solid black;
  border-radius: 50%;
  border-top: 6px solid transparent;
  border-bottom: 6px solid black;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alert-testnet {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-primary {
  background: #1c1b1e;
}

.text-gray {
  color: #6c6d76;
}

.pagecomponent .menu-icon {
  border-right: unset !important;
}

.header-menu {
  position: fixed;
  top: 4px;
  left: 10px;
  z-index: 99;
  display: flex;
}
.menu-icon {
  transform: translateY(5px);
}

@media screen and (max-height: 400px) {
  .alert-testnet {
    font-size: 8px !important;
    padding: 2px;
  }
}

.disabled-login-button {
  opacity: 0.2;
  pointer-events: none;
}

.disabled-login-button-text {
  color: red;
  font-size: 12px;
  position: absolute;
  width: 100%;
  margin-top: 24px;
}

.clock-container {
  border: 2px solid #383d65;
  padding-top: 32px;
  padding-bottom: 32px;
  border-radius: 10%;
  font-size: 40px;
  text-align: center;
  justify-content: center;
  background-color: #17192a;
}

.text-uppercase {
  text-transform: uppercase;
}

.page-component-game {
  background-color: #17192a !important;
  border: 3px solid #383d65 !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  transform: translateY(-7px) !important;
}

.pagecomponentgame {
  margin-left: calc(98vw - 97px) !important;
}

.pagecomponentgame .menu-icon {
  transform: translateY(-1px) !important;
}

.pagecomponentgame .menu-icon:hover img {
  filter: unset !important;
}

.pagecomponentgame button:focus {
  border: unset !important;
}
